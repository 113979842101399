<template>
  <component
    :is="tagComponent"
    v-kog-description:[getUniqueId(`kog-tag`)]="tooltipText"
    v-tooltip="{
      theme: 'kog-tooltip',
      content: tooltipLabel,
      boundary: 'document.body',
      popperClass: 'text-center',
    }"
    class="KogTag"
    :class="componentClasses"
    :tabindex="tabIndex"
    @click="onTagClick"
  >
    <kog-icon
      v-if="hasIcon"
      class="margin-right-xxs"
      theme="custom"
      :icon-class="iconClass"
      :is-svg-icon="isSvgIcon"
      :fa-style="iconStyle"
      :size="size === 's' ? 'xs' : 's'"
    />
    <span class="screenreader-only">
      {{ context }}
    </span>
    <span
      ref="content"
      class="KogTag-content"
      :aria-hidden="context.length > 0 ? 'true' : null"
      >{{ label }}</span
    >
    <kog-round-button
      v-if="hasDismissButton"
      class="KogTag-dismissButton"
      :aria-label="'remove tag: ' + label"
      size="xx-small"
      :button-style="dismissButtonType"
      icon-class="fa-times"
      @click="onDismiss"
    />
  </component>
</template>

<script>
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import { VTooltip } from 'floating-vue';

import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import KogDescription from 'sharedApp/directives/kog-description.js';

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

export default {
  name: 'KogTag',
  components: {
    KogRoundButton,
    KogIcon,
  },
  directives: {
    tooltip: VTooltip,
    KogDescription,
  },
  props: {
    /**
     * Provides additional information to screenreaders.<br>
     * If specified, it will be read out instead of the `label`.
     */
    context: {
      type: String,
      default: '',
    },
    /**
     * If `true`, activates the "Chip" functionality.
     * Visit the "Tags/Chip" stories for more info.
     */
    isChip: {
      type: Boolean,
      default: false,
    },
    /**
     * If `true`, shows an `x` button that fires the `dismiss` event.
     * Only available with `isChip: true` and `size: 'm'`
     */
    isDismissible: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets the class of the icon.
     * Use any type of font awesome icon class or custom svg icon.
     * Not available with `size: 's'`
     */
    iconClass: {
      type: String,
      default: '',
    },
    /**
     * Sets the style of the icon.
     * Only available for font awesome icons.
     */
    iconStyle: {
      type: String,
      default: 'regular',
      validator: prop => ['regular', 'solid', 'light'].includes(prop),
    },
    /**
     * Passed down to the `KogIcon` component
     */
    isSvgIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * Stand-in for the `click` event emitter, needed to check whether the click event is listened to.
     */
    onClick: {
      type: Function,
      default: null,
    },
    /**
     * The text to display on the Tag.
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Sets the size of the tag.
     * The `s` size variety cannot display a dismiss button.
     */
    size: {
      type: String,
      default: 'm',
      validator: value => ['s', 'm', 'l'].includes(value),
    },
    /**
     * Controls the background, border and text color.
     */
    type: {
      type: String,
      default: 'default',
      validator: value =>
        [
          'default',
          'basic',
          'outline',
          'outlineInformative',
          'informative',
          'informative2',
          'informative3',
          'warning',
          'outlineWarning',
          'positive',
          'negative',
        ].includes(value),
    },
    /**
     * Tooltip text. Appended to the label if label is truncated in display else shown on its own
     */
    tooltipText: {
      type: String,
      default: '',
    },
    /**
     * Tab index for the button element
     */
    tabIndex: {
      type: Number,
      default: null,
    },
    hideOverflow: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['dismiss'],
  setup() {
    const { getUniqueId } = useUniqueId();

    return {
      getUniqueId,
    };
  },
  data() {
    return {
      isOverflowing: false,
      resizeObserver: null,
    };
  },
  computed: {
    tagComponent() {
      return this.isButton ? 'button' : 'div';
    },
    isButton() {
      return typeof this.onClick === 'function';
    },
    componentClasses() {
      const buttonClass = this.isButton ? 'KogTag--button' : '';

      let sizeClass = '';
      switch (this.size) {
        case 's':
          sizeClass = 'KogTag--small';
          break;
        case 'l':
          sizeClass = 'KogTag--large';
          break;
        default:
          sizeClass = '';
      }

      const backgroundClass = `KogTag--${this.type}`;

      const iconClass = this.hasIcon ? 'KogTag--withIcon' : '';

      const chipClass = this.isChip ? 'KogTag--chip' : '';

      const dimissibleClass = this.hasDismissButton ? 'KogTag--dismissible' : '';

      const overflowHidden = this.hideOverflow ? 'KogTag-overflowHidden' : '';

      return [
        buttonClass,
        backgroundClass,
        sizeClass,
        iconClass,
        chipClass,
        dimissibleClass,
        overflowHidden,
      ];
    },
    hasIcon() {
      return !!this.iconClass;
    },
    hasDismissButton() {
      if (!this.isChip || this.size === 's' || this.isButton) {
        return false;
      }

      return this.isDismissible;
    },
    dismissButtonType() {
      if (this.type === 'negative') {
        return 'inverted';
      }

      return 'secondary-basic';
    },
    tooltipLabel() {
      if (this.isOverflowing)
        return this.tooltipText ? `${this.label}. ${this.tooltipText}` : this.label;
      return this.tooltipText;
    },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.setIsOverflowing).observe(this.$refs.content);
  },
  beforeUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.$refs.content);
    }
  },
  methods: {
    setIsOverflowing() {
      if (!this.$refs.content) return;

      const { scrollWidth, clientWidth } = this.$refs.content;
      this.isOverflowing = scrollWidth > clientWidth;
    },
    onTagClick(e) {
      if (this.isButton) {
        e.preventDefault();
        this.onClick(e);
      }
    },
    onDismiss() {
      /**
       * Emitted when the user clicks the `x` button
       * */
      this.$emit('dismiss', this.label);
    },
  },
};
</script>

<style scoped>
.KogTag {
  display: inline-flex;
  align-items: center;

  max-height: 24px;
  padding: var(--space-xxs);

  font-size: 12px;
  color: var(--kog-tag-color);

  background: var(--kog-tag-default-background-color);
  border-radius: 0;
}

.KogTag-overflowHidden {
  overflow: hidden;
}

.KogTag--button {
  border: 1px solid transparent;
}

.KogTag--button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
  border-color: var(--kogTransparent) !important;
}

.KogTag--chip {
  padding: var(--space-xxs) var(--space-xs);
  border-radius: 12px;
}

.KogTag--chip.KogTag--dismissible {
  padding: var(--space-xxs) var(--space-xxs) var(--space-xxs) var(--space-xs);
}

.KogTag-content {
  overflow: hidden;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.KogTag-dismissButton {
  margin-left: 6px;
}

.KogTag--small {
  max-height: 16px;
  padding: 0 var(--space-xxs);
  font-size: 11px;
}

.KogTag--large {
  min-width: 50px;
  min-height: 48px;
  padding: 12px;
  font-size: 14px;
}

.KogTag--withIcon {
  padding: var(--space-xxs) var(--space-xs);
}

.KogTag--basic {
  background: var(--kog-tag-basic-background-color);
}

.KogTag--outline {
  background: none;
  border: 1px solid var(--kog-tag-outline-border-color);
}

.KogTag--button:hover,
.KogTag--button.KogTag--basic:hover,
.KogTag--button.KogTag--outline:hover {
  border-color: var(--kog-tag-button-outline-hover-border-color);
}

.KogTag--outlineInformative {
  background: var(--kog-tag-outlineInformative-background-color);
  border: 1px solid var(--kog-tag-outlineInformative-border-color);
}

.KogTag--button.KogTag--outlineInformative:hover {
  border-color: var(--kog-tag-button-outlineInformative-hover-border-color);
}

.KogTag--informative {
  background: var(--kog-tag-informative-background-color);
}

.KogTag--button.KogTag--informative:hover {
  border-color: var(--kog-tag-button-informative-hover-border-color);
}

.KogTag--informative2 {
  background: var(--kog-tag-informative2-background-color);
}

.KogTag--button.KogTag--informative2:hover {
  border-color: var(--kog-tag-button-informative2-hover-border-color);
}

.KogTag--informative3 {
  background: var(--kog-tag-informative3-background-color);
}

.KogTag--button.KogTag--informative3:hover {
  border-color: var(--kog-tag-button-informative3-hover-border-color);
}

.KogTag--warning {
  background: var(--kog-tag-warning-background-color);
}

.KogTag--button.KogTag--warning:hover {
  border-color: var(--kog-tag-button-warning-hover-border-color);
}

.KogTag--outlineWarning {
  background: var(--kog-tag-outlineWarning-background-color);
  border: 1px solid var(--kog-tag-outlineWarning-border-color);
}

.KogTag--button.KogTag--outlineWarning:hover {
  border-color: var(--kog-tag-button-outlineWarning-hover-border-color);
}

.KogTag--positive {
  background: var(--kog-tag-positive-background-color);
}

.KogTag--button.KogTag--positive:hover {
  border-color: var(--kog-tag-button-positive-hover-border-color);
}

.KogTag--negative {
  color: var(--kog-tag-negative-color);
  background: var(--kog-tag-negative-background-color);
}

.KogTag--button.KogTag--negative:hover {
  border-color: var(--kog-tag-button-negative-hover-border-color);
}
</style>
