<template>
  <button
    ref="root"
    v-kog-description:[getUniqueId(`kog-button`)]="description || tooltip"
    v-tooltip="{
      theme: 'kog-tooltip',
      content: tooltip,
      popperClass: 'text-center',
      boundary: 'document.body',
    }"
    class="KogButton inline-block"
    :class="cssClasses"
    :disabled="disabled || isLoading ? 'true' : null"
    :aria-disabled="disabled || isLoading ? 'true' : null"
    :aria-label="ariaLabel || label"
    :aria-haspopup="hasDropdown ? true : null"
    :aria-expanded="hasDropdown ? (isDropdownOpen ? 'true' : 'false') : null"
    :aria-controls="controlsElementId"
    @click="handleClick"
  >
    <template v-if="isLoading && hasDropdown">
      <div class="flexContainer flexContainer-alignCenter">
        <kog-icon
          v-if="hasIcon"
          theme="custom"
          :icon-class="iconClass"
          :fa-style="iconStyle"
          :size="`${iconSize}-touch`"
        />
        <span class="padd-left-xs padd-right-xs">{{ label }}</span>
        <kog-button-loading-spinner />
      </div>
    </template>

    <template v-else-if="isLoading && hasIcon">
      <div class="flexContainer flexContainer-alignCenter">
        <kog-button-loading-spinner />
        <span class="padd-left-xs padd-right-xs">{{ label }}</span>
      </div>
    </template>

    <template v-else>
      <div class="KogButton-loader--covering">
        <kog-button-loading-spinner v-if="isLoading" />
      </div>
      <div
        class="flexContainer flexContainer-alignCenter"
        :class="{ 'KogButton-content--loading': isLoading }"
      >
        <kog-icon
          v-if="hasIcon && iconPosition === 'left'"
          theme="custom"
          :icon-class="iconClass"
          :fa-style="iconStyle"
          :size="`${iconSize}-touch`"
        />
        <span class="padd-left-xs padd-right-xs">{{ label }}</span>
        <kog-icon
          v-if="hasDropdown && showDropdownArrow"
          theme="custom"
          :icon-class="isDropdownOpen ? 'fa-caret-up' : 'fa-caret-down'"
          fa-style="solid"
          size="s-touch"
        />
        <kog-icon
          v-else-if="hasIcon && iconPosition === 'right'"
          theme="custom"
          :icon-class="iconClass"
          :fa-style="iconStyle"
          :size="`${iconSize}-touch`"
        />
      </div>
    </template>
  </button>
</template>

<script>
import { VTooltip } from 'floating-vue';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import KogDescription from 'sharedApp/directives/kog-description.js';

import KogButtonLoadingSpinner from './kog-button-loading-spinner.vue';

export const BUTTON_SIZES = Object.freeze(['small', 'medium', 'large']);

export const BUTTON_STYLES = Object.freeze(['danger', 'primary', 'accent', 'basic', 'positive']);

export const BUTTON_ICON_SIZES = Object.freeze(['m', 's']);

export const BUTTON_ICON_STYLES = Object.freeze(['solid', 'regular', 'light']);

export const BUTTON_ICON_POSITIONS = Object.freeze(['left', 'right']);

export default {
  name: 'KogButton',
  components: {
    KogIcon,
    KogButtonLoadingSpinner,
  },
  directives: {
    tooltip: VTooltip,
    KogDescription,
  },
  props: {
    /**
     * Optional message to be read out by assistive screen reader technologies.
     */
    ariaLabel: {
      type: String,
      default: '',
    },
    /**
     * Controls if button is clickable or not.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Controls if caret icon should be shown to the right of the label.<br>
     * Should only be used by `kog-dropdown-button`.
     */
    hasDropdown: {
      type: Boolean,
      default: false,
    },
    /**
     * With dropdown enabled, used as aria-controls value.
     * */
    controlsElementId: {
      type: String,
      default: null,
    },
    /**
     * Controls if an icon should be shown to left of button label.
     */
    hasIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * font-awesome icon class
     */
    iconClass: {
      type: String,
      default: '',
    },
    /**
     * If the icon should be solid, regular, or light (font-awesome icon variations).
     */
    iconStyle: {
      type: String,
      default: 'regular',
      validator: prop => BUTTON_ICON_STYLES.includes(prop),
    },
    iconSize: {
      type: String,
      default: 's',
      validator(value) {
        return !value || BUTTON_ICON_SIZES.includes(value);
      },
    },
    /**
     * Controls the placement of the icon in relation to the label.<br>
     * NOTE: Prop value `'right'` should not be used together with `hasDropdown: true`.
     */
    iconPosition: {
      type: String,
      default: 'left',
      validator: prop => BUTTON_ICON_POSITIONS.includes(prop),
    },
    /**
     * Controls the caret icon direction and the background, border, and color of button.<br>
     * Only used with `kog-dropdown-button`.
     */
    isDropdownOpen: {
      type: Boolean,
      default: false,
    },
    /**
     * When having dropdown, controls if the up / down arrow is shown.
     * */
    showDropdownArrow: {
      type: Boolean,
      default: true,
    },
    /**
     * Toggles if loading spinner should be shown in button.
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * The text that should be displayed in the button.
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Controls padding, height, and minimum width of the button.
     */
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return !value || BUTTON_SIZES.includes(value);
      },
    },
    /**
     * Controls the styles of the button in terms of background,<br>
     * borders, text, and hover/focus/disabled states.
     */
    buttonStyle: {
      type: String,
      default: '',
      validator(value) {
        return !value || BUTTON_STYLES.includes(value);
      },
    },
    /**
     * Optional text to be shown in tooltip on button hover.<br>
     * Tooltip is centered above button.
     */
    tooltip: {
      type: String,
      default: '',
    },
    /**
     * Prop to add extra description to the button for users
     *  using screen readers.
     */
    description: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  setup() {
    const { getUniqueId } = useUniqueId();

    return {
      getUniqueId,
    };
  },
  computed: {
    cssClasses() {
      const isDefault = this.buttonStyle === '';
      const styleClass = isDefault ? '' : `KogButton--${this.buttonStyle}`;
      const sizeClass = `KogButton--${this.size}`;
      let loadingClass = '';
      if (this.isLoading) {
        if (isDefault) {
          loadingClass = 'KogButton--loading';
        } else {
          loadingClass = `KogButton--${this.buttonStyle}--loading`;
        }
      }
      const dropdownClass = this.isDropdownOpen
        ? `KogButton--${this.buttonStyle ? this.buttonStyle : 'default'}--dropdownOpen`
        : '';

      return [styleClass, sizeClass, loadingClass, dropdownClass];
    },
  },
  methods: {
    handleClick(_) {
      if (this.disabled || this.isLoading) {
        return;
      }
      /**
       * Emitted when the user clicks the button
       * */
      this.$emit('click', _);
    },
  },
};
</script>

<style scoped>
/* default style */

.KogButton {
  cursor: pointer;
  user-select: none;

  position: relative;

  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  height: 40px;
  padding: 8px 24px;

  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-default);
  font-weight: 500;
  line-height: 24px;
  color: var(--kog-button-default-label-default);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  background: var(--kog-button-default-background-default);
  border-color: var(--kog-button-default-border-default);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  outline: none;

  transition:
    background 0.1s ease,
    border-color 0.1s ease;
}

.KogButton:focus {
  color: var(--kog-button-default-label-focus);
}

.KogButton[aria-disabled='true'] {
  cursor: not-allowed;
  color: var(--kog-button-default-label-disabled);
  background: var(--kog-button-default-background-disabled);
  border-color: var(--kog-button-default-border-disabled);
}

.KogButton:hover:not([aria-disabled='true']) {
  color: var(--kog-button-default-label-hover);
  background: var(--kog-button-default-background-hover);
}

.KogButton:active:not([aria-disabled='true']),
.KogButton.KogButton--default--dropdownOpen {
  color: var(--kog-button-default-label-active);
  border-color: var(--kog-button-default-border-active);
}

.KogButton--loading[aria-disabled='true'] {
  color: var(--kog-button-default-label-loading);
  background: var(--kog-button-default-background-loading);
  border-color: var(--kog-button-default-border-loading);
}

.KogButton-loader--covering {
  position: absolute;
}

.KogButton-content--loading {
  visibility: hidden;
}

/* primary style */

.KogButton--primary {
  color: var(--kog-button-primary-label-default);
  background: var(--kog-button-primary-background-default);
  border-color: var(--kog-button-primary-border-default);
  transition:
    background 0.2s ease,
    border-color 0.2s ease;
}

.KogButton--primary:focus {
  color: var(--kog-button-primary-label-focus);
}

.KogButton--primary[aria-disabled='true'] {
  color: var(--kog-button-primary-label-disabled);
  background: var(--kog-button-primary-background-disabled);
  border-color: var(--kog-button-primary-border-disabled);
}

.KogButton--primary:hover:not([aria-disabled='true']) {
  color: var(--kog-button-primary-label-hover);
  background: var(--kog-button-primary-background-hover);
  border-color: var(--kog-button-primary-border-hover);
}

.KogButton--primary:active:not([aria-disabled='true']),
.KogButton.KogButton--primary--dropdownOpen {
  color: var(--kog-button-primary-label-active);
  background: var(--kog-button-primary-background-active);
  border-color: var(--kog-button-primary-border-active);
}

.KogButton--primary--loading[aria-disabled='true'] {
  color: var(--kog-button-primary-label-loading);
  background: var(--kog-button-primary-background-loading);
  border-color: var(--kog-button-primary-border-loading);
}

.KogButton--primary--loading :deep(.KogButtonLoadingSpinner-circle::before) {
  background: var(--kog-button-primary-label-loading);
}

/* accent style */

.KogButton--accent {
  color: var(--kog-button-accent-label-default);
  background: var(--kog-button-accent-background-default);
  border-color: var(--kog-button-accent-border-default);
  transition:
    background 0.2s ease,
    border-color 0.2s ease;
}

.KogButton--accent:focus {
  color: var(--kog-button-accent-label-focus);
}

.KogButton--accent[aria-disabled='true'] {
  color: var(--kog-button-accent-label-disabled);
  background: var(--kog-button-accent-background-disabled);
  border-color: var(--kog-button-accent-border-disabled);
}

.KogButton--accent:hover:not([aria-disabled='true']) {
  color: var(--kog-button-accent-label-hover);
  background: var(--kog-button-accent-background-hover);
  border-color: var(--kog-button-accent-border-hover);
}

.KogButton--accent:active:not([aria-disabled='true']),
.KogButton.KogButton--accent--dropdownOpen {
  color: var(--kog-button-accent-label-active);
  background: var(--kog-button-accent-background-active);
  border-color: var(--kog-button-accent-border-active);
}

.KogButton--accent--loading[aria-disabled='true'] {
  color: var(--kog-button-accent-label-loading);
  background: var(--kog-button-accent-loading-background);
  border-color: var(--kog-button-accent-border-loading);
}

.KogButton--accent--loading :deep(.KogButtonLoadingSpinner-circle::before) {
  background: var(--kog-button-accent-label-loading);
}

/* danger style */

.KogButton--danger {
  color: var(--kog-button-danger-label-default);
  background: var(--kog-button-danger-background-default);
  border-color: var(--kog-button-danger-border-default);
  transition:
    background 0.2s ease,
    border-color 0.2s ease;
}

.KogButton--danger:focus {
  color: var(--kog-button-danger-label-focus);
}

.KogButton--danger[aria-disabled='true'] {
  color: var(--kog-button-danger-label-disabled);
  background: var(--kog-button-danger-background-disabled);
  border-color: var(--kog-button-danger-border-disabled);
}

.KogButton--danger:hover:not([aria-disabled='true']) {
  color: var(--kog-button-danger-label-hover);
  background: var(--kog-button-danger-background-hover);
  border-color: var(--kog-button-danger-border-hover);
}

.KogButton--danger:active:not([aria-disabled='true']),
.KogButton.KogButton--danger--dropdownOpen {
  color: var(--kog-button-danger-label-active);
  background: var(--kog-button-danger-background-active);
  border-color: var(--kog-button-danger-border-active);
}

.KogButton--danger--loading[aria-disabled='true'] {
  color: var(--kog-button-danger-label-loading);
  background: var(--kog-button-danger-loading-background);
  border-color: var(--kog-button-danger-border-loading);
}

.KogButton--danger--loading :deep(.KogButtonLoadingSpinner-circle::before) {
  background: var(--kog-button-danger-label-loading);
}

/* positive style */

.KogButton--positive {
  color: var(--kog-button-success-label-default);
  background: var(--kog-button-success-background-default);
  border-color: var(--kog-button-success-border-default);
  transition:
    background 0.2s ease,
    border-color 0.2s ease;
}

.KogButton--positive:focus {
  color: var(--kog-button-success-label-focus);
}

.KogButton--positive[aria-disabled='true'] {
  color: var(--kog-button-success-label-disabled);
  background: var(--kog-button-success-background-disabled);
  border-color: var(--kog-button-success-border-disabled);
}

.KogButton--positive:hover:not([aria-disabled='true']) {
  color: var(--kog-button-success-label-hover);
  background: var(--kog-button-success-background-hover);
  border-color: var(--kog-button-success-border-hover);
}

.KogButton--positive:active:not([aria-disabled='true']),
.KogButton.KogButton--positive--dropdownOpen {
  color: var(--kog-button-success-label-active);
  background: var(--kog-button-success-background-active);
  border-color: var(--kog-button-success-border-active);
}

.KogButton--positive--loading[aria-disabled='true'] {
  color: var(--kog-button-success-label-loading);
  background: var(--kog-button-success-background-loading);
  border-color: var(--kog-button-success-border-loading);
}

.KogButton--positive--loading :deep(.KogButtonLoadingSpinner-circle::before) {
  background: var(--kog-button-success-label-loading);
}

/* basic style */

.KogButton--basic {
  color: var(--kog-button-basic-label-default);
  background: transparent;
  transition:
    background 0.2s ease,
    border-color 0.2s ease;
}

.KogButton--basic:not([aria-disabled='true']) {
  border: none;
}

.KogButton--basic:focus {
  color: var(--kog-button-basic-label-focus);
}

.KogButton--basic[aria-disabled='true'] {
  color: var(--kog-button-basic-label-disabled);
  background: var(--kog-button-basic-background-disabled);
  border-color: var(--kog-button-basic-border-disabled);
}

.KogButton--basic:hover:not([aria-disabled='true']) {
  color: var(--kog-button-basic-label-default);
  background: var(--kog-button-basic-background-hover);
}

.KogButton--basic:active:not([aria-disabled='true']),
.KogButton.KogButton--basic--dropdownOpen {
  color: var(--kog-button-basic-label-active);
  background: var(--kog-button-basic-background-active);
}

.KogButton--basic--loading[aria-disabled='true'] {
  color: var(--kog-button-basic-label-loading);
  background: var(--kog-button-basic-background-loading);
}

.KogButton--basic--loading :deep(.KogButtonLoadingSpinner-circle::before) {
  background: var(--kog-button-basic-label-loading);
}

/* sizes */

.KogButton--large {
  min-width: 130px;
  height: 48px;
  padding: 12px;
}

.KogButton--medium {
  min-width: 130px;
  height: 40px;
  padding: 8px;
}

.KogButton--small {
  min-width: 85px;
  height: 32px;
  padding: 4px;
}
</style>
