<template>
  <div
    ref="root"
    :role="role"
    @keydown.up.stop="focusPreviousItem($event)"
    @keydown.down.stop="focusNextItem($event)"
    @keydown.left.stop="focusPreviousItem($event)"
    @keydown.right.stop="focusNextItem($event)"
    @keydown.page-up.stop="focusFirstItem"
    @keydown.page-down.stop="focusLastItem"
    @focusin="onElementFocused($event)"
    @keydown="$emit('keydown', $event)"
  >
    <slot />
  </div>
</template>

<script>
import { fromEvent, Subject } from 'rxjs';

import { getAllFocusableElements } from 'sharedApp/utils/dom-utils.js';
import { initTypingBuffer } from 'sharedApp/utils/typing-buffer.js';

/**
 * Wrapper component that provides keyboard navigation
 * for any list of interactive elements.
 */
export default {
  name: 'KogItemList',
  props: {
    autoFocus: {
      type: Boolean,
      default: false,
    },
    focusableElementsSelector: {
      type: String,
      default: '',
    },
    role: {
      type: String,
      default: null,
      validator: val => ['list', 'listbox', 'radiogroup', 'tablist', 'toolbar'].includes(val),
    },
    isHandlingTabIndex: {
      type: Boolean,
      default: false,
    },
    isSearchByTypingEnabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['keydown'],
  data: () => ({
    currentFocusedElement: null,
    destroyed$: new Subject(),
  }),
  mounted() {
    if (this.isSearchByTypingEnabled) {
      const keyDown$ = fromEvent(this.$refs.root, 'keydown');
      initTypingBuffer(keyDown$, this.destroyed$, this.focusItemByText);
    }

    if (this.isHandlingTabIndex) {
      this.setItemTabIndexes();
    }

    if (this.autoFocus) {
      this.setInitiallyFocusedItem();
    }
  },
  beforeUnmount() {
    this.destroyed$.next();
  },
  methods: {
    findInitiallyFocusedItem() {
      const focusableItems = getAllFocusableElements(
        this.$refs.root,
        this.focusableElementsSelector,
      );
      if (focusableItems.length === 0) {
        return null;
      }

      const userFocusableItems = focusableItems.filter(el => el.tabIndex === 0);

      let focusableItem;

      if (userFocusableItems.length > 0) {
        [focusableItem] = userFocusableItems;
      } else {
        [focusableItem] = focusableItems;
      }

      return focusableItem;
    },
    setInitiallyFocusedItem() {
      const itemToFocus = this.findInitiallyFocusedItem();
      if (itemToFocus) {
        this.trySetFocusOnItem(itemToFocus, 10);
      }
    },
    setItemTabIndexes() {
      const focusableElements = getAllFocusableElements(
        this.$refs.root,
        this.focusableElementsSelector,
      );

      focusableElements.forEach((element, index) => {
        const tabindex = index === 0 ? 0 : -1;
        // eslint-disable-next-line no-param-reassign
        element.tabIndex = tabindex;
      });
    },
    // we need more than one attempt at setting the focus because
    // v-popover `show` and `apply-show` events fire while the dropdown has `visibility="hidden"`
    // and as such is not focusable
    trySetFocusOnItem(itemToFocus, maxTries) {
      itemToFocus.focus();
      const isFocusOnItemSuccess = document.activeElement === itemToFocus;
      if (maxTries > 0 && !isFocusOnItemSuccess) {
        setTimeout(() => {
          this.trySetFocusOnItem(itemToFocus, maxTries - 1);
        }, 10);
      }
    },
    focusPreviousItem(e) {
      if (this.isTextInput(e.target)) {
        return;
      }
      if (e) {
        e.preventDefault();
      }

      const focusableItems = getAllFocusableElements(
        this.$refs.root,
        this.focusableElementsSelector,
      );

      if (focusableItems.length === 0) {
        return;
      }

      const currentFocusedIndex = focusableItems.indexOf(document.activeElement);
      if (currentFocusedIndex > 0) {
        const itemToFocus = focusableItems[currentFocusedIndex - 1];
        itemToFocus.focus();
      }
    },
    focusNextItem(e) {
      if (this.isTextInput(e.target)) {
        return;
      }
      if (e) {
        e.preventDefault();
      }

      const focusableItems = getAllFocusableElements(
        this.$refs.root,
        this.focusableElementsSelector,
      );

      if (focusableItems.length === 0) {
        return;
      }

      const currentFocusedIndex = focusableItems.indexOf(document.activeElement);
      if (currentFocusedIndex < focusableItems.length - 1) {
        const itemToFocus = focusableItems[currentFocusedIndex + 1];
        itemToFocus.focus();
      }
    },
    focusFirstItem() {
      const focusableItems = getAllFocusableElements(
        this.$refs.root,
        this.focusableElementsSelector,
      );

      if (focusableItems.length === 0) {
        return;
      }

      const [itemToFocus] = focusableItems;
      itemToFocus.focus();
    },
    focusLastItem() {
      const focusableItems = getAllFocusableElements(
        this.$refs.root,
        this.focusableElementsSelector,
      );

      if (focusableItems.length === 0) {
        return;
      }

      const itemToFocus = focusableItems[focusableItems.length - 1];
      itemToFocus.focus();
    },
    focusItemByText(text) {
      const focusableItems = getAllFocusableElements(
        this.$refs.root,
        this.focusableElementsSelector,
      );

      const itemToFocus = focusableItems.find(item => {
        const itemText = item.textContent.trim().toLowerCase();
        return itemText.startsWith(text);
      });

      if (itemToFocus) {
        itemToFocus.focus();
      }
    },
    onElementFocused(e) {
      if (!this.isHandlingTabIndex) {
        return;
      }

      const newFocusedElement = e.target;
      if (newFocusedElement.tabIndex !== 0) {
        newFocusedElement.tabIndex = 0;
        if (this.currentFocusedElement) {
          this.currentFocusedElement.tabIndex = -1;
        }
      }

      this.currentFocusedElement = newFocusedElement;
    },
    isTextInput(element) {
      const { tagName, type } = element;
      if (tagName === 'INPUT') {
        const validType = [
          'text',
          'password',
          'number',
          'email',
          'tel',
          'url',
          'search',
          'date',
          'datetime',
          'datetime-local',
          'time',
          'month',
          'week',
        ];
        return validType.includes(type);
      }
      return false;
    },
  },
};
</script>
