<template>
  <button
    ref="root"
    v-kog-description:[getUniqueId(`kog-round-button`)]="tooltip"
    v-tooltip="{
      theme: 'kog-tooltip',
      content: tooltip,
      popperClass: 'text-center',
      boundary: 'document.body',
    }"
    class="KogRoundButton flexContainer flexContainer-center"
    :class="componentClasses"
    :aria-disabled="disabled || isLoading ? 'true' : null"
    :aria-label="ariaLabel"
    @click="handleClick"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
    @focusin="$emit('focusin', $event)"
    @focusout="$emit('focusout', $event)"
  >
    <template v-if="isLoading">
      <div class="flexContainer flexContainer-alignCenter">
        <kog-button-loading-spinner />
      </div>
    </template>
    <template v-else>
      <kog-icon
        theme="custom"
        :icon-class="iconClass"
        :fa-style="iconStyle"
        :size="computedIconSize"
      />
    </template>
  </button>
</template>

<script>
import { VTooltip } from 'floating-vue';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import KogDescription from 'sharedApp/directives/kog-description.js';

import KogButtonLoadingSpinner from './kog-button-loading-spinner.vue';

export default {
  name: 'KogRoundButton',
  components: {
    KogIcon,
    KogButtonLoadingSpinner,
  },
  directives: {
    tooltip: VTooltip,
    KogDescription,
  },
  props: {
    /**
     * Sets the button to its active state when `true`
     */
    activated: {
      type: Boolean,
      default: false,
    },
    /**
     * Provides a descriptive label for screen readers to use in place of the visual icon.
     */
    ariaLabel: {
      type: String,
      required: true,
    },
    /**
     * Controls if button is clickable or not.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets the class of the icon.
     * Use any type of font awesome icon class.
     */
    iconClass: {
      type: String,
      required: true,
    },
    /**
     * Sets the style of the icon.
     */
    iconStyle: {
      type: String,
      default: 'regular',
      validator: prop => ['solid', 'regular', 'light'].includes(prop),
    },
    /**
     * Sets the size of the button.
     * The icon size is inferred from this prop by default, but can also be specified explicitely.
     */
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        const validTypes = ['medium', 'small', 'x-small', 'xx-small'];
        return !value || validTypes.includes(value);
      },
    },
    /**
     * Sets the size of the icon.
     * This overrides the default computed value, which only returns `s` or `xs`
     */
    iconSize: {
      type: String,
      default: null,
      validator(value) {
        const validTypes = ['m', 's', 'xs'];
        return !value || validTypes.includes(value);
      },
    },
    /**
     * Sets the style of the button
     */
    buttonStyle: {
      type: String,
      default: 'primary',
      validator(value) {
        const validTypes = [
          'default',
          'primary',
          'accent',
          'basic',
          'danger',
          'positive',
          'secondary-basic',
          'secondary-outline',
          'inverted',
        ];
        return !value || validTypes.includes(value);
      },
    },
    /**
     * Optional text to be shown in tooltip on button hover.<br>
     * Tooltip is centered above button.
     */
    tooltip: {
      type: String,
      default: '',
    },
    /**
     * Toggles if loading spinner should be shown in button.
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'mouseenter', 'mouseleave', 'focusin', 'focusout'],
  setup() {
    const { getUniqueId } = useUniqueId();

    return {
      getUniqueId,
    };
  },
  computed: {
    componentClasses() {
      const classes = [`KogRoundButton--${this.buttonStyle}`, `KogRoundButton--${this.size}`];

      if (this.activated) {
        classes.push(`KogRoundButton--${this.buttonStyle}--active`);
      }
      return classes;
    },
    computedIconSize() {
      if (this.iconSize !== null) {
        return this.iconSize;
      }
      return this.size === 'xx-small' ? 'xs' : 's';
    },
  },
  methods: {
    handleClick(_) {
      if (this.disabled || this.isLoading) {
        return;
      }
      /**
       * Emitted when the user clicks the button
       * */
      this.$emit('click', _);
    },
  },
};
</script>

<style scoped>
/* default style */

.KogRoundButton {
  border-style: solid;
  border-width: 1px;
  border-radius: 100%;
  transition:
    background 0.2s ease,
    border-color 0.2s ease;
}

.KogRoundButton[aria-disabled='true'] {
  cursor: not-allowed;
}

/* primary style */

.KogRoundButton--primary {
  color: var(--kog-round-button-primary-color);
  background: var(--kog-round-button-primary-background);
  border: none;
}

.KogRoundButton--primary[aria-disabled='true'] {
  color: var(--kog-round-button-primary-disabled-color);
  background: var(--kog-round-button-primary-disabled-background);
}

.KogRoundButton--primary:hover:not([aria-disabled='true']) {
  color: var(--kog-round-button-primary-hover-color);
  background: var(--kog-round-button-primary-hover-background);
}

.KogRoundButton--primary:active:not([aria-disabled='true']),
.KogRoundButton--primary--active:not([aria-disabled='true']) {
  color: var(--kog-round-button-primary-active-color);
  background: var(--kog-round-button-primary-active-background);
}

/* accent style */

.KogRoundButton--accent {
  color: var(--kog-round-button-accent-color);
  background: var(--kog-round-button-accent-background);
  border: none;
}

.KogRoundButton--accent[aria-disabled='true'] {
  color: var(--kog-round-button-accent-disabled-color);
  background: var(--kog-round-button-accent-disabled-background);
}

.KogRoundButton--accent:hover:not([aria-disabled='true']) {
  color: var(--kog-round-button-accent-hover-color);
  background: var(--kog-round-button-accent-hover-background);
}

.KogRoundButton--accent:active:not([aria-disabled='true']),
.KogRoundButton--accent--active:not([aria-disabled='true']) {
  color: var(--kog-round-button-accent-active-color);
  background: var(--kog-round-button-accent-active-background);
}

/* basic style */

.KogRoundButton--basic {
  color: var(--kog-round-button-basic-color);
  background: var(--kog-round-button-basic-background);
  border: none;
}

.KogRoundButton--basic[aria-disabled='true'] {
  color: var(--kog-round-button-basic-disabled-color);
  background: var(--kog-round-button-basic-disabled-background);
}

.KogRoundButton--basic:hover:not([aria-disabled='true']) {
  color: var(--kog-round-button-basic-hover-color);
  background: var(--kog-round-button-basic-hover-background);
}

.KogRoundButton--basic:active:not([aria-disabled='true']),
.KogRoundButton--basic--active:not([aria-disabled='true']) {
  color: var(--kog-round-button-basic-active-color);
  background: var(--kog-round-button-basic-active-background);
}

/* danger style */

.KogRoundButton--danger {
  color: var(--kog-round-button-danger-color);
  background: var(--kog-round-button-danger-background);
  border: none;
}

.KogRoundButton--danger[aria-disabled='true'] {
  color: var(--kog-round-button-danger-disabled-color);
  background: var(--kog-round-button-danger-disabled-background);
}

.KogRoundButton--danger:hover:not([aria-disabled='true']) {
  color: var(--kog-round-button-danger-hover-color);
  background: var(--kog-round-button-danger-hover-background);
}

.KogRoundButton--danger:active:not([aria-disabled='true']),
.KogRoundButton--danger--active:not([aria-disabled='true']) {
  color: var(--kog-round-button-danger-active-color);
  background: var(--kog-round-button-danger-active-background);
}

/* positive style */

.KogRoundButton--positive {
  color: var(--kog-round-button-positive-color);
  background: var(--kog-round-button-positive-background);
  border: none;
}

.KogRoundButton--positive[aria-disabled='true'] {
  color: var(--kog-round-button-positive-disabled-color);
  background: var(--kog-round-button-positive-disabled-background);
}

.KogRoundButton--positive:hover:not([aria-disabled='true']) {
  color: var(--kog-round-button-positive-hover-color);
  background: var(--kog-round-button-positive-hover-background);
}

.KogRoundButton--positive:active:not([aria-disabled='true']),
.KogRoundButton--positive--active:not([aria-disabled='true']) {
  color: var(--kog-round-button-positive-active-color);
  background: var(--kog-round-button-positive-active-background);
}

/* default style */

.KogRoundButton--default {
  color: var(--kog-round-button-default-color);
  background: var(--kog-round-button-default-background);
  border-color: var(--kog-round-button-default-border-color);
}

.KogRoundButton--default[aria-disabled='true'] {
  color: var(--kog-round-button-default-disabled-color);
  background: var(--kog-round-button-default-disabled-background);
  border-color: var(--kog-round-button-default-disabled-border-color);
}

.KogRoundButton--default:hover:not([aria-disabled='true']) {
  color: var(--kog-round-button-default-hover-color);
  background: var(--kog-round-button-default-hover-background);
}

.KogRoundButton--default:active:not([aria-disabled='true']),
.KogRoundButton--default--active:not([aria-disabled='true']) {
  color: var(--kog-round-button-default-active-color);
  background: var(--kog-round-button-default-active-background);
  border-color: var(--kog-round-button-default-active-border-color);
}

/* secondary-basic style */

.KogRoundButton--secondary-basic {
  color: var(--kog-round-button-secondary-basic-color);
  background: var(--kog-round-button-secondary-basic-background);
  border: none;
}

.KogRoundButton--secondary-basic[aria-disabled='true'] {
  color: var(--kog-round-button-secondary-basic-disabled-color);
  background: var(--kog-round-button-secondary-basic-disabled-background);
}

.KogRoundButton--secondary-basic:hover:not([aria-disabled='true']) {
  color: var(--kog-round-button-secondary-basic-hover-color);
  background: var(--kog-round-button-secondary-basic-hover-background);
}

.KogRoundButton--secondary-basic:active:not([aria-disabled='true']),
.KogRoundButton--secondary-basic--active:not([aria-disabled='true']) {
  color: var(--kog-round-button-secondary-basic-active-color);
  background: var(--kog-round-button-secondary-basic-active-background);
}

/* secondary-outline style */

.KogRoundButton--secondary-outline {
  color: var(--kog-round-button-secondary-outline-color);
  background: var(--kog-round-button-secondary-outline-background);
  border-color: var(--kog-round-button-secondary-outline-border-color);
}

.KogRoundButton--secondary-outline[aria-disabled='true'] {
  color: var(--kog-round-button-secondary-outline-disabled-color);
  background: var(--kog-round-button-secondary-outline-disabled-background);
  border-color: var(--kog-round-button-secondary-outline-disabled-border-color);
}

.KogRoundButton--secondary-outline:hover:not([aria-disabled='true']) {
  color: var(--kog-round-button-secondary-outline-hover-color);
  background: var(--kog-round-button-secondary-outline-hover-background);
  border-color: var(--kog-round-button-secondary-outline-hover-border-color);
}

.KogRoundButton--secondary-outline:active:not([aria-disabled='true']),
.KogRoundButton--secondary-outline--active:not([aria-disabled='true']) {
  color: var(--kog-round-button-secondary-outline-active-color);
  background: var(--kog-round-button-secondary-outline-active-background);
  border-color: var(--kog-round-button-secondary-outline-active-border-color);
}

/* inverted style */

.KogRoundButton--inverted {
  color: var(--kog-round-button-inverted-color);
  background: var(--kog-round-button-inverted-background);
  border: none;
}

.KogRoundButton--inverted[aria-disabled='true'] {
  color: var(--kog-round-button-inverted-disabled-color);
  background: var(--kog-round-button-inverted-disabled-background);
}

.KogRoundButton--inverted:hover:not([aria-disabled='true']) {
  color: var(--kog-round-button-inverted-hover-color);
  background: var(--kog-round-button-inverted-hover-background);
}

.KogRoundButton--inverted:active:not([aria-disabled='true']),
.KogRoundButton--inverted--active:not([aria-disabled='true']) {
  color: var(--kog-round-button-inverted-active-color);
  background: var(--kog-round-button-inverted-active-background);
}

/* sizes */

.KogRoundButton--medium {
  width: var(--space-xxl);
  min-width: var(--space-xxl);
  height: var(--space-xxl);
}

.KogRoundButton--small {
  width: var(--space-xl);
  min-width: var(--space-xl);
  height: var(--space-xl);
}

.KogRoundButton--x-small {
  width: var(--space-l);
  min-width: var(--space-l);
  height: var(--space-l);
  padding: 0;
}

.KogRoundButton--xx-small {
  width: var(--space-m);
  min-width: var(--space-m);
  height: var(--space-m);
  padding: 0;
}
</style>
